<template>
    <div class="calendar-icon" :style="small?'font-size:0.6rem;':''">
      <div class="month" v-if="showDayNotMonth">{{ dayOfWeek }}</div>
      <div class="month" v-else>{{ month }}</div>
      <div class="day" :style="small?'font-size:1.2rem;':''">{{ day }}</div>
      <div class="time" v-if="showTime">{{ time?time:'archive' }}<small v-if="time && !multiDay">{{ timeZoneName }}</small></div>
    </div>
</template>

<script>

    import { ref } from "vue"


    export default {
        components: {

        },
        props: {
          startDate: {
            type: String,
            required: true
          },
          endDate: {
            type: String,
            required: false
          },
          showDayNotMonth: {
            type: Boolean,
            required: false
          },
          showTime: {
            type: Boolean,
            required: false
          },
          small: {
            type: Boolean,
            required: false,
            default: false
          }
        },
        setup(props) {

          const nthNumber = (number) => {
            if (number > 3 && number < 21) return "th"
            switch (number % 10) {
              case 1:
                return "st"
              case 2:
                return "nd"
              case 3:
                return "rd"
              default:
                return "th"
            }
          }

          const newStartDate = new Date(props.startDate)
          const day = ref(null)
          const dayOfWeek = ref(null)
          const ordinal = ref(null)
          const month = ref(null)
          const year = ref(null)
          const time = ref(null)
          const timeZoneName = ref(null)
          const multiDay = ref(false)


          day.value = newStartDate.getDate()
          ordinal.value = nthNumber(newStartDate.getDate())
          month.value = newStartDate.toLocaleString('default', {month: 'long'})
          dayOfWeek.value = newStartDate.toLocaleString('default', {weekday: 'long'})
          year.value = newStartDate.getFullYear()
          const timeParts = newStartDate.toLocaleString('default', {hour: 'numeric', minute: 'numeric', timeZoneName: 'short', hour12: false}).split(' ')
          time.value = timeParts[0]
          timeZoneName.value = timeParts[1]

          if(props.endDate) {
            const newEndDate = new Date(props.endDate)

            // is multi day?
            if(newStartDate.toDateString() !== newEndDate.toDateString()) {
              multiDay.value = true

              // time
              time.value = Math.ceil((newEndDate - newStartDate) / (1000*60*60*24)) + ' day event'
            } else {
              // time
              let endTime
              endTime = newEndDate.toLocaleString('default', {hour: 'numeric', minute: 'numeric', hour12: false})
              endTime = endTime.replace(' ', '')
              time.value += ' - ' + endTime
            }

            if(props.endDate === props.startDate) time.value = null


          }


          return {
              day, month, year, time, ordinal, dayOfWeek, timeZoneName, multiDay
          }

        },
        methods: {

        }
    }
</script>

