import axios from '@axios'
import jwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    fetchOrgs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/orgs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrg(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/orgs/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrgMembers(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/orgs/${id}/members`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchOrgContent(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/orgs/${id}/content`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchTeam(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/team', { params: { perPage: 1000, ...queryParams } })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${id}`, userData)
          .then(response => {
            localStorage.setItem(jwt.jwtConfig.storageUserDataKeyName, JSON.stringify(response.data.results[0]));
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchReports(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reports', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReport(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/reports/${id}/file`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/files/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchFileFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/files/${id}/file`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/events', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPastEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/events/past', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchFutureEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/events/future', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNewsArticles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/news-articles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNewsArticle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/news-articles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFeedItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/content-items-feed', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchResearchBlogs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/research-articles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchResearchBlog(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/research-articles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchKnowledgeBaseArticles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/kb-articles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchKnowledgeBaseArticle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/kb-articles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAskDwgArticles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ask-dwg-articles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAskDwgArticle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/ask-dwg-articles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGalleries(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/galleries', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGallery(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/galleries/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVideos(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/playlists', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVideo(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/playlists/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/topics', { params: { perPage: 1000, ...queryParams } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopic(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/topics/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopicContent(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/topics/${id}/content`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchStyleGuide(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pages/7034', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExtranetPage(ctx, { slug, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/extranet-pages/${slug}`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchPublicPage(ctx, { slug, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/public-pages/${slug}`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    checkUserAgreement() {
      return new Promise((resolve, reject) => {
        axios
          .get('/agreements/user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLatestAgreement() {
      return new Promise((resolve, reject) => {
        axios
          .get('/agreements/latest')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    acceptAgreement() {
      return new Promise((resolve, reject) => {
        axios
          .put('/agreements/accept')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    optIn (ctx, {selected}) {  //TODO - we should move this to /user update endpoint once this is implemented
      return new Promise((resolve, reject) => {
        axios
          .post('/users/me/optin', {selected:selected})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    recordAnalytic(ctx, {analyticData}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/recordanalytic`, analyticData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
