<template>
    <div class="card content-item-card mb-3 flex-md-row">
        <div v-if="contentType.singleSlug === 'event' && new Date(contentItem.content.start) > new Date()" class="card-header text-center bg-transparent p-0" style="flex-basis: 14%;">
          <b-link :to="{ name: contentType.singleSlug, params: { slug: contentItem.slug }}">
            <calendar-icon :startDate="contentItem.content.start" small/>
          </b-link>
        </div>

        <div v-else-if="contentItem.thumbnail_url" class="card-header text-center bg-transparent p-0" style="flex-basis: 14%;">
          <b-link :to="{ name: contentType.singleSlug, params: { slug: contentItem.slug }}">
            <img v-if="contentType.singleSlug === 'report' && contentItem.thumbnail_sizes !== undefined" class="img-fluid bg-img" :alt="contentItem.title" :src="contentItem.thumbnail_sizes.medium.url" style="aspect-ratio: unset;" >
            <img v-else class="img-fluid bg-img" :alt="contentItem.title" :src="contentItem.thumbnail_url" width="600" height="600">
          </b-link>
        </div>

        <div v-else class="card-header text-center bg-transparent p-0" style="flex-basis: 14%;">
          <b-link :to="{ name: contentType.singleSlug, params: { slug: contentItem.slug }}">
            <img class="img-fluid bg-img" :alt="contentItem.title" :src="getCategoryImage(contentItem.category,contentItem.content_type)" width="600" height="600">
          </b-link>
        </div>

        <div class="card-body p-2 m-0">
            <b-link :to="{ name: contentType.singleSlug, params: { slug: contentItem.slug }}" class="text-secondary-light">
                <p class="mb-1">{{ contentItem.title }}</p>
                <b-badge v-if="showContentType" variant="primary">{{ contentType.singular }}</b-badge>
                <b-badge v-if="contentItem.category" variant="danger" class="ml-1">{{ contentItem.category }}</b-badge>
            </b-link>
        </div>
    </div>
</template>

<script>

    import { ref } from "vue"
    import {
        BBadge, BLink
    } from 'bootstrap-vue'
    import {getCategoryImage, getContentType} from '/src/@core/utils/utils'
    import CalendarIcon from "@/components/CalendarIcon.vue";

    export default {
        components: {
          CalendarIcon,
            BBadge, BLink
        },
        props: {
            contentItem: {
                type: Object,
                required: true
            },
            showContentType: {
                type: Boolean,
                required: false,
            },
            showOwner: {
                type: Boolean,
                required: false,
            },
        },
        setup(props) {


            const contentType = ref( null )

            contentType.value = (getContentType(props.contentItem.content_type))


            return {
                contentType,
            }

        },
        methods: {
          getCategoryImage,
            publishedDate: dateString => {
                const date = new Date(dateString)

                return date.toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })
            }
        }
    }
</script>
