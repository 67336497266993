<template>
  <!-- Shape section divider -->
  <div class="shape-wrapper">
    <div class="shape shape-bottom shape-fluid-x" :class="css">
      <svg viewBox="0 0 1440 140" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 80 L0 180 L1440 140 L1440 0 L300 140 Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShapeDivider',
  props: {
    css: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>


