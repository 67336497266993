<template>
    <div class="card content-item-card mb-3 flex-md-row">
        <div class="card-header text-center bg-transparent p-0" style="flex-basis: 14%;">
            <b-link :to="{ name: 'staff', params: { id: contentItem.id }}">
                <img class="img-fluid bg-img" :alt="contentItem.name" :src="contentItem.photo_url || '/img/placeholder-user.webp'" width="600" height="600">
            </b-link>
        </div>

        <div class="card-body p-2 m-0">
            <b-link :to="{ name: 'staff', params: { id: contentItem.id }}" class="text-secondary-light">
                <p class="mb-1">{{ contentItem.name }}</p>
                <b-badge v-if="showContentType" variant="secondary">Person</b-badge>
            </b-link>



        </div>
    </div>
</template>

<script>

    import {
        BBadge, BLink
    } from 'bootstrap-vue'

    export default {
        components: {
            BBadge, BLink
        },
        props: {
            contentItem: {
                type: Object,
                required: true
            },
            showContentType: {
                type: Boolean,
                required: false,
            },
        },
    }
</script>
