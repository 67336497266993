import Vue from 'vue'
import { BootstrapVue, IconsPlugin, ModalPlugin } from 'bootstrap-vue'
import Hotjar from 'vue-hotjar'

import router from './router'
import store from './store'
import App from './App.vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ModalPlugin)
Vue.use(Hotjar, {
  id: '4960899',
  isProduction: true,
});

// Import Bootstrap etc.
import './assets/sass/frontend.scss'

// Import InstantSearch
import InstantSearch from 'vue-instantsearch'
Vue.use(InstantSearch)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

